@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800;900&family=Inter:wght@300;400;600;700&family=Montserrat:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');


@import 'variables';

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
@import 'responsive';
@import 'utilities';
@import 'reboot';
@import 'all';
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

